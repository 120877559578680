@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

#three-canvas-container {
  height: 100vh !important;
}

.login-button {
  background-color: #ff6f00;
}

#three-canvas-container-2 {
  box-sizing: border-box;

  position: absolute;
  width: 699px;
  height: 1024px;
  left: 741px;
  top: 0px;
}

.errorMsg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorMsg img {
  width: 25px;
  height: 25px;
  padding: 3px;
}

.errorMsg p {
  color: red;
}

.registerForm {
  margin-top: 150px;
}

.retro-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  position: fixed;
  bottom: 300px;
  left: 20p;
  width: 310px;
  height: 150px;
}

.dialog-box {
  width: 300px;
  height: 280px;
  padding: 10px;
  background-color: #fff;
  border: 4px solid #000;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 14px;
  color: #000;
  overflow: hidden;
}

.breadCrumb-Container {
  position: absolute;
  top: 0;
  left: 0;
}

.breadCrumb {
  background-color: #db4c4c;
  border: 1px solid black;
  font-size: 5px;
  color: white;
  padding: 5px;
  border-radius: 10px;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  z-index: 3;
}

.breadCrumbDisabled {
  background-color: #9c7c7c;
  border: 1px solid black;
  font-size: 5px;
  color: white;
  padding: 5px;
  border-radius: 10px;
  font-size: 16px;
  position: relative;
  cursor: block;
  z-index: 3;
}

.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: (-50%, -50%, 0);
  border-radius: 50%;
  border: 2px solid white;
}

span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

p {
  color: black;
}
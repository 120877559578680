.loading-screen {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .loading-image {
    width: 100%;
    height: 100%;    
    object-fit: cover;
  }
  
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-family: Arial, sans-serif;
  }
  
  .loading-message {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .loading-notice {
    font-size: 14px;
    text-align: center;
  }

  .loading-screen p{
    color: whitesmoke;
  }
  